$fa-font-path: "../../vendor/components-font-awesome/fonts";
$icon-font-path: "../../vendor/bootstrap-sass/assets/fonts/bootstrap/";
@import "node_modules/components-font-awesome/scss/font-awesome";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";



h1{
  //color:#519AF7;
  margin-top:1em;
}
body{
  font-family: 'Open Sans', sans-serif;
}

.container.small{
  max-width:800px;
  font-size:100%;
}


.dropzone{
  border: 4px dashed rgba(0, 0, 0, 0.3);
  font-size: 2em;
  color: #ccc;
}
img{
  max-width:100%;
}

.usage-btn{
  width:135px;
  height:100px;
  background:white;
  border:none;
  margin-top:10px;

  img{
    max-width:100%;
  }

  .panel {
    &:focus, &:active, &:hover {
      box-shadow: 0px 0px 5px #D48900;
      background: #FFF6CC;
      outline: none;
    }
  }

}

.highlighted{
  box-shadow: 0px 0px 5px #D48900;
  background: #FFF6CC;
  outline: none;  
}

/* Images homespage */
#img-garantie{
  max-width:441px;
}

#img-het-pakket{
  max-width:370px;
}


#s-pakket, #s-beschikbaar{
  background: #ECE9E2;
}

.gallery{

  //overflow:auto;
  img{
    display:block;
    float:left;
    //max-width:150px;
    //margin-right: 1em;
    cursor: pointer;
  }
}

.margin-bottom{
  margin-bottom: 2em;
}

.how{
  overflow: auto;
  .wrap{
    padding:2em;
  }
  p{
    font-size:1.3em;
  }
  .left{
    text-align:center;
    float:left;
    width:20%;
    padding-right:1em;

    p{
      max-width:10em;
      font-size:1.5em;
    }
  }

  .right{
    width:80%;
    padding:2em 0 0 0;
    float:left;
  }

  p.extra-big{
    font-size:4em;
    font-weight: bold;
  }
}


html,body{
  height: 100%;
}

.dev-only{
  background:#F9A1A1;
  padding:3em;
  margin-top:10em;

}

.btn-steps{
  background:#e8842e;
  color:white;
  border:none;
  padding:1em 3em;
  font-weight:bold;

  border-radius:5px;
  &.back{
    background:white;
    color:#000;
  }
}

.form-group.overflow{
  overflow: auto;
}
.navbar-header{
  &.right{
    position: absolute;
    right: 27px;
    top: 29px;
    width: 100px;
    height: 100px;
  }
  .navbar-brand{
    //width:10em;
    //height:3em;
    //margin-top:10%;
    padding:0;

    img{
      max-width:114px;
    }
  }
}

#main-nav {
  margin-bottom:0;
  background:#fbfbfb;
  border:0;
  .nav {
    //width:600px;
    //float:none;
    //margin:0 auto;

    margin-left:2em;
    li{
      border-bottom:5px solid transparent;

      &.active {

        border-color:#fb5447;
        a {
          color:#fb5447;
          background: transparent;
        }
      }
    }
    a {
      font-size:0.9em;
      color: #000000;
      display: block;
      padding-top: 2em;
      padding-bottom: 2em;
      padding-left:1.5em;
      padding-right:1.5em;
      font-weight:600;
    }

    .dropdown-menu{
      a{
        padding-bottom:1em;
        padding-top:1em;

      }
    }

  }
}


#checker{
  //background: #106e94;
  background: rgba(16,110,148,0.8);
  background: rgb(251, 84, 71);
  color:white;
  max-width:50em;
  padding:2em;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(50, 50, 50, 0.58);
  -moz-box-shadow:    0px 0px 14px 0px rgba(50, 50, 50, 0.58);
  box-shadow:         0px 0px 14px 0px rgba(50, 50, 50, 0.58);

  h2{
    font-size:2.5em;
  }

  p{
    font-size:1.2em;
    text-align:center;
    margin:2em auto;
    max-width:35em;
  }

  label{
    font-size:.8em;
    text-transform: uppercase;
  }

  #roof-check-btn{
    background:#eeb333;
    background:#FFFFFF;
    border-radius:0;
    border:none;
    padding:1em;
    display: block;
    text-align: center;
    width:100%;
    font-size: 1.2em;
    font-weight: bold;

    color:black;
  }

  p.bottom-link{
    text-align: center;
    margin-top:1em;
    a {
      color: white;
      text-decoration: underline;

    }
  }

  input[type="text"]{
    background:transparent;
    border-radius:0;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom:1px solid white;
    color:white;
    outline:none;

    &:focus{
      outline:none;
    }
    &::placeholder{
      color: #CCC;
      font-weight: 100;
    }
  }
}

.scroll-to-wrapper{
  text-align: center;
  padding:3em 0;
  .scroll-to{
    font-size:3em;
    color:#676767;
  }
}




/* SLIDER */

/*#####################
Additional Styles (required)
######################*/

#panel-choice .thumbnail {

  &:hover{
    cursor:pointer;

  }
  margin: 1em 0;
  background: #DEDEDE;
  border: 1px solid #B5B5B5;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(50, 50, 50, 0.58);
  -moz-box-shadow:    0px 0px 14px 0px rgba(50, 50, 50, 0.58);
  box-shadow:         0px 0px 14px 0px rgba(50, 50, 50, 0.58);

  .list-group-item{
    background:#EFEFEF;
  }

  font-weight:normal;

  .message{
    visibility: hidden;
  }

  input:checked + .message {
    visibility: visible;
  }

  &.best{
    background:#C9EAC9;

    strong{
      display:block;
      background:#64AF65;
      color:white;
      padding:0.5em 1em;
      text-align: center;
      margin:-4px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .list-group-item{
      background:#DDFFDD;

    }
  }
}
.carousel-control.left, .carousel-control.right {
  background-image:none !important;
}
.carousel-control {
  color:#fff;
  top:40%;
  color:#428BCA;
  bottom:auto;
  padding-top:4px;
  width:30px;
  height:30px;
  text-shadow:none;
  opacity:1;
}
.carousel-control:hover {
  color: #d9534f;
}
.carousel-control.left, .carousel-control.right {
  background-image:none !important;
}
.carousel-control.right {
  left:auto;
  right:-32px;
}
.carousel-control.left {
  right:auto;
  left:-32px;
}

.carousel-indicators {
  bottom:-30px;
}
.carousel-indicators li {
  border-radius:0;
  width:10px;
  height:10px;
  background:#ccc;
  border:1px solid #ccc;
}
.carousel-indicators .active {
  width:12px;
  height:12px;
  background:#3276b1;
  border-color:#3276b1;
}




/* Make the banner touch the nav (bypass the nav margin-bottom) */
#homepage-banner{
  margin-top:0px;
  margin-bottom:0;
  height:100%;
  min-height:700px;
  position:relative;
  background: rgba(0,0,0,0);
  &::after{
    content:"";
    background-size: cover;
    top: 0;
    left: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index:-1;
  }


  h2{
    text-align:center;
    margin-bottom:1em;
  }
  .form-group{
    max-width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  input{
    font-size:2em;
    height:auto;
  }
}

#s-footer-image{
  //  width:100%;
  //height: 100%;
  //background:url(../images/new/energie_service.jpg) no-repeat;
  //background-size:;
  img{
    width:100%;
  }
}


/* Debug */

#cookie-debug{
  background:black;
  color: #00ff3d;
  width:300px;
  height:500px;
  word-wrap:break-word;
  position:fixed;
  right:0;
  bottom:0;
  padding:2em;
  overflow-y:auto;

  p{
    margin-top: 1em;
  }
  span{
    color:white;
  }
}
.breadcrumbs-wrapper{
  background:#F3F0E9;
  color:black;
  padding:4em 0;
  overflow-x:scroll;

  .bar{
    height:5px;
    background: #d9d6d1;
    width:90%;
    position:relative;

    .prog{
      background:#68C54D;
      height:5px;
    }

    .step{
      position:absolute;
      top:-8px;
      left:0;

      &.active{
        .circle{
          i{
            color:#68C54D;
          }
        }
      }
      .circle{

      }
      .label{
        color:#635957;
      }
      &#step-meassurment{
        left:33%;
      }

      &#step-usage{
        left:66%;
      }

      &#step-result{
        left:100%;
      }
    }

    &.done {
      .prog {
        background: #84E468;
      }
      .step {
        &.active {
          .circle {
            i {
              color: #84E468 !important;
            }
          }
        }
      }
    }

    i{
      color:white;
      font-size: 1.8em;
    }
  }
}

i.lampje{
  font-size: 1.5em;
  color: #737373;
}

p.bigger{
  font-size: 1.2em;
}

.step-wrapper{
  overflow: auto;
  padding-top:2em;
  padding-bottom: 2em;

  .form-control.limited{
    max-width:158px;
  }

  label{
    .fa-info-circle{
      color:#1790c9;
    }
  }

  .house-wrapper{
    position:relative;
    width:300px;
    height:289px;
    background:url(../images/huis-60.png) no-repeat;
    margin:0 auto;

    &.flat{
      background: url(../images/huis-flat.png) no-repeat;
    }

    .m{
      width:140px;
      position:absolute;

      &.width{
        top:0;
        left:0;
      }

      &.height{
        top:0;
        right:0;
      }
    }
  }







  .form-group.radio-space label{
    margin:0 1em;
    padding:1em;
    border:1px solid #ccc;
  }

  .img-radio{
    position: relative;
    margin:0 1em;
    .bg{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:1;
    }
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding:1em;
    input:checked + div.bg{
      border: 1px solid green;
      background: rgba(139, 251, 120, 0.1);
    }

    img{
      max-width:60px;
    }
  }
}

p.readable{
  max-width: 693px;
  line-height: 170%
}

.form-group.extra{
  margin:5em 1em;
}

section.quickcheck{
  color: #5a4846;
  word-wrap: break-word;
  background:#fffffe;
  margin:3em 0;
  padding:2em;
  border-radius: 10px;

  -webkit-box-shadow: 7px 22px 12px -26px rgba(0,0,0,0.75);
  -moz-box-shadow: 7px 22px 12px -26px rgba(0,0,0,0.75);
  box-shadow: 7px 22px 12px -26px rgba(0,0,0,0.75);
}

section.homepage{
  background:#f3f0e9;
  color:#5a4846;
  word-wrap: break-word;

  .content-wrapper{
    padding:3em;
  }
  &.full{
    height:100%;
  }

  h2{
    font-size:2.7em;
    font-weight: 300;
    margin-bottom:0.5em;
  }
  p{
    font-size:1.1em;
  }
  a{
    font-weight: bold;
  }

  .homepage-row{
    padding-top: 10em;
    padding-bottom: 10em;
  }

  .col{
    //padding-top:10em;
    //padding-bottom:10em;
    &.left{
      text-align:right;
    }

    .text-wrap{
      max-width:100%;
    }

    &.instructions{
      padding:3em 1em;
    }
  }
  .counter-clients{
    margin-top:2em;

    p{
      font-size:0.8em;
      margin-bottom:-0.3em;
    }

    .count{

      font-size:3em;
      i{
        color:#eeb333;
      }
    }
  }

  .wrap{
    margin:3em 0;
  }
}

footer{
  color:white;
  background:#4E4E4E;
  img{
    width:150px;
  }

  a{
    color:#A9A9A9;
    &:hover{
      color:white;
    }
  }

  ul{
    padding:0;
  }
  li{
    display: block;
    padding-top:.5em;
    padding-bottom:.5em;
  }

  .wrap{
    padding:3em;
  }


}

.btn-steps.link{
  color:white !important;
  text-decoration: none;

}
#home-availability{
  margin-top:29px;
}

.wrapper-how-table{
  background: #E1DED9;
  padding:2em 5em;
}

#offer{
  background:white;

  .heading{
    overflow: auto;
    img.logo{
      max-width:150px;
      &.left{
        float:left;
      }

      &.right{
        float:right;
        margin-top:50px;
      }
    }
  }
}


table{
  tr.chosen{
    background: orange !important;
    color: black;
    font-weight: bold;
  }
}


#cp-side-nav{
  margin-top:3em;
}

#login-div{
  .outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .middle {
    display: table-cell;
    vertical-align: middle;
  }

  .inner {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
}


#carousel-example-generic, #carousel-example-generic2{
  max-width: 800px;
  margin: 0 auto;
}
#how-table{

  > table {
    tbody {

      td {
        vertical-align: top;
        padding: 2em;
        margin: 0 2em;

        &.number {
          font-size: 3em;
          padding-top: 1em;
        }
        &.full {
          width: 100%;
        }

        border-bottom: 1px solid #ccc;

      }

      h3 {
        font-size: 1.3em;
        font-weight: bold;
      }

      tr:last-child {
        td {
          border: none;
        }
      }
    }
  }

  dd{
    margin-bottom:10px;
  }

}

#module-faq{
  .panel-heading{
    background:#E1DED9;
    border-radius: 0;
    padding:2em;
  }

  a {

    color: #5D5D5D;
    text-decoration: none;
    &:hover{
      color:black;
      .panel-heading {
        background:#CCC;
      }
    }


  }

  .panel-title{
    font-weight: bold;
  }

  i{
    font-size:0.8em;
    color: #989898;
    font-weight: normal;
    margin-right:20px;
  }


  .panel {
    margin-bottom: 0;
    border: none;

  }

  .panel-body{
    li{
      font-size:1.2em;
    }
    padding:2em;
  }
}

@media(max-width: 991px){
  section.homepage{

    .col{
      &.left {
        text-align: center;
      }

      .text-wrap{
        width:350px;
        margin:0 auto;
      }
    }
  }
}

@media (max-width: 767px) {

  .breadcrumbs-wrapper{
    overflow-x:scroll;
  }

  .wrapper-how-table{
    padding:2em 1em;
  }
  #how-table{
    tbody {
      td {
        padding: 0.5em;
      }
    }

  }

  .navbar-header{

    .navbar-brand{
      margin: 16px 0 0 20px;
      height: 35px;

      img{
        max-width:123px;
        margin-top:1.2em;

      }
    }
  }

  #faq{
    i{
      display:none;
    }


  }



  #main-nav{

    border:0;
    margin-top:0;

    .nav {
      margin-left: 0;
    }
    .navbar-header{

	    .navbar-brand{
	
	      img{
	        margin-top: 0.1em;
	
	      }
	    }
	}
  }
  .modal-xl {
    width: 90%;
    max-width:1200px;
  }

  section.homepage{
    h2{
      font-size:2.5em;
    }
  }


  footer{
    .pull-left, .pull-right{
      display:block;
      float:none !important;
      &.wrap{
        padding:3em 0;
      }
    }
  }


}

.register-form {
	padding-top: 40px;
}